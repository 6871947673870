import React, { useEffect, useRef } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import useToastify from "hooks/useToastify";
import { usePedVisitFlow } from "hooks/useVisitFlow";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";

import { ped_sub_steps } from "helpers/enum";
import { OtherConditionSchema } from "helpers/validationSchema";

import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import CSSLoader from "components/cssLoader/CSSLoader";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import Button from "components/buttons/Button";

const SelectCondition = ({
  incompleteData,
  updateVisit,
  updatingVisit,
  selected,
  setSelected,
  gettingConcerns,
  visitConcernsPayload,
  setActiveIndex,
}) => {
  const formRef = useRef();
  const { showAlert } = useToastify();

  const dispatch = useDispatch();
  const { subSteps } = usePedVisitFlow();
  const currentScreenValueFromStore =
    subSteps[ped_sub_steps.select_condition].value;

  useEffect(() => {
    if (
      !selected &&
      currentScreenValueFromStore?.length &&
      visitConcernsPayload?.length
    ) {
      if (
        visitConcernsPayload?.find(
          (el) =>
            el?.toLowerCase() === currentScreenValueFromStore?.toLowerCase()
        )
      ) {
        setSelected(currentScreenValueFromStore);
      } else {
        setSelected(visitConcernsPayload?.[visitConcernsPayload?.length - 1]);
      }
    }
  }, [
    currentScreenValueFromStore,
    visitConcernsPayload,
    selected,
    setSelected,
  ]);

  function onRightButtonClick(_, nextAnimation) {
    if (selected === visitConcernsPayload?.[visitConcernsPayload?.length - 1]) {
      formRef?.current?.handleSubmit();
    } else {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            skin_issue: selected,
            allergies: incompleteData?.allergies_json,
            medications:
              typeof incompleteData?.medications[0] === "string"
                ? incompleteData?.medications.map((item) => ({
                    name: item,
                  }))
                : incompleteData?.medications?.map((med) => {
                    return {
                      ...med?.details,
                      name: med?.name,
                    };
                  }),
            pediatric_second_phase_steps: {
              ...incompleteData?.pediatric_second_phase_steps,
              [ped_sub_steps.select_condition]: "completed",
            },
          },
        },
        change_current_screen: true,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            pedVisitFlowReducers.select_condition({
              value: selected,
            })
          );
          showAlert("Progress Saved Successfully", "success");
          setActiveIndex(2);
        },
      });
    }
  }

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          skin_issue: values?.other_condition,
          pediatric_second_phase_steps: {
            ...incompleteData?.pediatric_second_phase_steps,
            [ped_sub_steps.select_condition]: "completed",
          },
        },
      },
      change_current_screen: true,
      screen_to_complete: null,
      callBack: () => {
        dispatch(
          pedVisitFlowReducers.select_condition({
            value: values?.other_condition,
          })
        );
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(2);
      },
    });
  }

  function isLast() {
    return (
      visitConcernsPayload?.length &&
      selected &&
      visitConcernsPayload?.[visitConcernsPayload?.length - 1] === selected
    );
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl p-10 relative overflow-x-hidden">
      {gettingConcerns ? (
        <div className={"h-40 flex items-center justify-center"}>
          <CSSLoader className={"w-16 h-16"} color={"orange"} />
        </div>
      ) : (
        <RadioButtonCard
          options={visitConcernsPayload}
          onSelect={setSelected}
          selected={selected}
        />
      )}
      {isLast() ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              innerRef={formRef}
              onSubmit={onFormSubmit}
              initialValues={{
                other_condition:
                  currentScreenValueFromStore &&
                  visitConcernsPayload?.find(
                    (el) =>
                      el?.toLowerCase() !==
                      currentScreenValueFromStore?.toLowerCase()
                  )
                    ? currentScreenValueFromStore
                    : "",
              }}
              validationSchema={OtherConditionSchema}
            >
              {() => (
                <Form>
                  <Input
                    id={"other_condition"}
                    name={"other_condition"}
                    label="Type your concern"
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
      <Button
        disabled={!selected}
        className="mt-10 py-4"
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
};

export default SelectCondition;
