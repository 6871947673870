const input_types = {
  PHONE: "tel",
  DROPDOWN: "dropdown",
  SELECT: "datalist",
  DATEPICKER: "datepicker",
  UNIT: "unit",
  WITHLOADER: "with_loader",
  OTP: "otp",
  CREATABLE: "creatable",
};
const login_type = {
  EMAIL: 1,
  GOOGLE: 2,
  PHONE: 3,
};

const visit_status = {
  pending: 1,
  evaluated: 2,
  rejected: 5,
  canceled: 6,
  complete_awaiting_ma: 7,
  incomplete: 8,
  partially_completed: 9,
  pending_payment_approval: 10,
};

const step_status = {
  completed: "completed",
  current: "current",
  upcoming: "upcoming",
};

const speciality = {
  dermatology: 1,
  primary_care: 2,
  cosmetic: 3,
  ped: 4,
  pediatric: 4,
};

const dermatology_main_steps = {
  basic_info: "basic_info",
  visit_details: "visit_details",
  photos: "photos",
  main_symptoms: "main_symptoms",
  main_medical_history: "main_medical_history",
};

const dermatology_sub_steps = {
  select_patient: "select_patient",
  select_child: "select_child",
  select_someone_else: "select_someone_else",
  confirm_residence: "confirm_residence",
  patient_bio: "patient_bio",
  identity_confirmation: "identity_confirmation",
  select_condition: "select_condition",
  select_affected_part: "select_affected_part",
  condition_details: "condition_details",
  upload_photos: "upload_photos",
  symptoms: "symptoms",
  symptom_details: "symptom_details",
  associated_symptoms: "associated_symptoms",
  more_about_symptoms: "more_about_symptoms",
  medical_history: "medical_history",
  female_medical_history: "female_medical_history",
  allergies: "allergies",
  medications: "medications",
  skin_care_products: "skin_care_products",
  select_pharmacy: "select_pharmacy",
  previous_treatment_feedback: "previous_treatment_feedback",
  share_treatment_plan: "share_treatment_plan",
  other_feedbacks: "other_feedbacks",
};

const primary_care_main_steps = {
  basic_info: "basic_info",
  visit_details: "visit_details",
  main_select_slot: "main_select_slot",
  additional_details: "additional_details",
};

const primary_care_sub_steps = {
  allergies: "allergies",
  medications: "medications",
  female_medical_history: "female_medical_history",
  select_patient: "select_patient",
  select_child: "select_child",
  select_someone_else: "select_someone_else",
  confirm_residence: "confirm_residence",
  identity_confirmation: "identity_confirmation",
  select_condition: "select_condition",
  symptoms: "symptoms",
  select_slot: "select_slot",
  select_pharmacy: "select_pharmacy",
  additional_info: "additional_info",
};

const cosmetic_steps = {
  select_condition: "select_condition",
  upload_photos: "upload_photos",
  allergies: "allergies",
  select_slot: "select_slot",
  previous_treatment: "previous_treatment",
};

const ped_main_steps = {
  basic_info: "basic_info",
  visit_details: "visit_details",
  main_select_slot: "main_select_slot",
  additional_details: "additional_details",
};

const ped_sub_steps = {
  allergies: "allergies",
  medications: "medications",
  female_medical_history: "female_medical_history",
  select_patient: "select_patient",
  confirm_residence: "confirm_residence",
  identity_confirmation: "identity_confirmation",
  select_condition: "select_condition",
  symptoms: "symptoms",
  select_slot: "select_slot",
  select_pharmacy: "select_pharmacy",
  additional_info: "additional_info",
};

const health_profile_sections = {
  prescription_history: "Prescription History",
  medical_history: "Medical History",
  allergies: "Allergies",
  medications: "Medications",
  surgical_procedures_or_hospitalisations:
    "Surgical Procedures or Hospitalisations",
  family_history: "Family History",
  female_health_care: "Female Health Care",
  social_history: "Social History",
  marital_status: "Marital Status",
  alcohol_use: "Alcohol Use",
  tobacco_use: "Tobacco Use",
  exercise: "Exercise",
  recreational_drugs: "Recreational Drugs",
  sexual_activity: "Sexual Activity",
  caffeine_usage: "Caffeine Usage",
  special_diet: "Special Diet",
  vaccinations: "Vaccinations",
};

const health_profile_status = {
  completed: "Completed",
  incomplete: "Incomplete",
};

export {
  input_types,
  login_type,
  visit_status,
  step_status,
  speciality,
  dermatology_main_steps,
  dermatology_sub_steps,
  primary_care_main_steps,
  primary_care_sub_steps,
  cosmetic_steps,
  ped_main_steps,
  ped_sub_steps,
  health_profile_sections,
  health_profile_status,
};
