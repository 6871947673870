import React, { useEffect, useRef } from "react";
import useHealthProfile from "hooks/useHealthProfile";
import { useHistory, useParams } from "react-router-dom";
import CSSLoader from "components/cssLoader/CSSLoader";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileQuestion from "../HealthProfileQuestion";
import HealthProfileRadio from "../HealthProfileRadio";
import { Form, Formik } from "formik";
import AddHealthProfileForMedications from "./AddHealthProfileForMedications";
import { HealthProfileMedicationsSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";
import { medicationDosageForms } from "helpers/statics";

const YESNO = ["Yes", "No"];

function HPMedications() {
  const formRef = useRef();
  const history = useHistory();
  const { patient_id } = useParams();
  const { healthPayload, postHealthProfile, postingHealthProfile } =
    useHealthProfile();

  useEffect(() => {
    if (healthPayload) {
      formRef?.current?.setValues({
        on_medication:
          healthPayload?.health_profile?.on_medication === "not_on_medication"
            ? "No"
            : healthPayload?.health_profile?.medications?.length > 0
            ? "Yes"
            : healthPayload?.health_profile?.on_medication ===
              "is_on_medication"
            ? "Yes"
            : "",
        activeMeds: healthPayload?.health_profile?.medications?.filter(
          (med) => med?.status === "active"
        )?.length
          ? healthPayload?.health_profile?.medications
              ?.filter((med) => med?.status === "active")
              ?.map((el) => {
                let result = {
                  id: el?.id,
                  drug_name: el?.drug_name,
                  DispensableDrugId: el?.details?.dispensable_drug_id,
                  RoutedDoseFormDrugId: el?.details?.routed_dose_form_drug_id,
                  NDC: el?.details?.ndc,
                  RXCUI: el?.details?.rx_cui,
                  Strength: el?.details?.strength,
                  IsObsolete: el?.details?.is_obsolete,
                  dose: el?.details?.dose,
                  frequency: el?.details?.frequency,
                  per: el?.details?.per,
                  form: medicationDosageForms?.includes(el?.details?.form)
                    ? el?.details?.form
                    : el?.details?.form?.length > 0
                    ? "Other"
                    : "",
                  status: el?.status,
                };

                if (result?.form === "Other") {
                  result.other_form = el?.details?.form;
                }

                if (result?.status === "inactive") {
                  result.inactivated_at = el?.inactivated_at;
                  result.inactivated_by = el?.inactivated_by;
                }

                return result;
              })
          : [],
        inactiveMeds: healthPayload?.health_profile?.medications?.filter(
          (med) => med?.status === "inactive"
        )?.length
          ? healthPayload?.health_profile?.medications
              ?.filter((med) => med?.status === "inactive")
              ?.map((el) => {
                let result = {
                  id: el?.id,
                  drug_name: el?.drug_name,
                  DispensableDrugId: el?.details?.dispensable_drug_id,
                  RoutedDoseFormDrugId: el?.details?.routed_dose_form_drug_id,
                  NDC: el?.details?.ndc,
                  RXCUI: el?.details?.rx_cui,
                  Strength: el?.details?.strength,
                  IsObsolete: el?.details?.is_obsolete,
                  dose: el?.details?.dose,
                  frequency: el?.details?.frequency,
                  per: el?.details?.per,
                  form: medicationDosageForms?.includes(el?.details?.form)
                    ? el?.details?.form
                    : el?.details?.form?.length > 0
                    ? "Other"
                    : "",
                  status: el?.status,
                  inactivated_at: el?.inactivated_at,
                  inactivated_by: el?.inactivated_by,
                };

                if (result?.form === "Other") {
                  result.other_form = el?.details?.form;
                }

                return result;
              })
          : [],
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {};
    if (values?.on_medication === "Yes") {
      payload = {
        on_medication: 2,
        health_profile_medications_attributes: values?.activeMeds
          ?.concat(values?.inactiveMeds)
          ?.map((el) => {
            let result = {
              id: el?.id,
              drug_name: el?.drug_name,
              status: el?.status,
              details: {
                drug_name: el?.drug_name,
                dispensable_drug_id: el?.DispensableDrugId,
                routed_dose_form_drug_id: el?.RoutedDoseFormDrugId,
                ndc: el?.NDC,
                rx_cui: el?.RXCUI,
                strength: el?.Strength,
                is_obsolete: el?.IsObsolete,
                dose: el?.dose,
                frequency: el?.frequency,
                per: el?.per,
                form: el?.form === "Other" ? el?.other_form : el?.form,
              },
            };

            return result;
          }),
      };
    } else {
      payload = {
        on_medication: 1,
        health_profile_medications_attributes: [],
      };
    }
    postHealthProfile({ patientId: patient_id, payload, completed });
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        onSubmit={onSave}
        innerRef={formRef}
        initialValues={{
          on_medication: "",
          activeMeds: [],
          inactiveMeds: [],
        }}
        validationSchema={HealthProfileMedicationsSchema}
      >
        {({ setFieldValue, values }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Medications"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="px-12 py-6 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please list all your medications.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion
                text={
                  "List any medications you are currently taking and/or review your current medication list."
                }
              />
              <div className="flex items-center mt-8">
                {YESNO.map((option, optionIndex) => (
                  <div className="flex flex-1" key={optionIndex}>
                    <HealthProfileRadio
                      text={option}
                      selected={values?.on_medication === option ? true : false}
                      onClick={() => setFieldValue("on_medication", option)}
                    />
                  </div>
                ))}
              </div>
              {values?.on_medication === "Yes" ? (
                <div className="pb-12">
                  <AddHealthProfileForMedications
                    medication_forms={medicationDosageForms}
                    setFieldValue={setFieldValue}
                    activeMeds={values?.activeMeds}
                  />
                </div>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HPMedications;
