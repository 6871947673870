import TextWithBulletPoint from "../TextWithBulletPoint";

export const getMedicationsView = (payload) => {
  if (payload?.medications?.filter((med) => med?.status === "active")?.length) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {payload?.medications
          ?.filter((med) => med?.status === "active")
          ?.map((el, idx) => {
            return (
              <div className="mb-4" key={idx}>
                <TextWithBulletPoint
                  text={`${el?.drug_name}${
                    el?.details?.dose ? `, ${el?.details?.dose}` : ""
                  }${
                    el?.details?.frequency ? `, ${el?.details?.frequency}` : ""
                  }${el?.details?.per ? `, per ${el?.details?.per}` : ""}${
                    el?.details?.form ? `, ${el?.details?.form}` : ""
                  }`}
                />
              </div>
            );
          })}
      </div>
    );
  } else {
    return null;
  }
};
