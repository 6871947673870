import Images from "assets/images";
import React from "react";

function RegularRadioSelect({
  options,
  label,
  selected,
  setSelected,
  vertical = true,
  alternateOptionLabels = [],
  required,
}) {
  return (
    <div>
      <label
        className={`text-2xl font-bold text-opacity-50 text-indigo  ${
          required && "required-field"
        }`}
      >
        {label ? label : "Gender at Birth"}
      </label>
      <div
        className={`flex space-y-0 flex-row pt-4 ${
          vertical ? "md:flex-col md:space-y-2" : "space-x-2"
        }`}
      >
        {options?.map((el, idx) => {
          return (
            <div
              onClick={() => setSelected(el)}
              key={idx}
              className={`flex items-center cursor-pointer ${
                idx !== options?.length ? "mr-12" : ""
              }`}
            >
              <img
                className="w-10 h-10"
                src={
                  selected === el ? Images.radioChecked : Images.uncheckedCircle
                }
                alt="radio"
              />
              <p className="pl-5 text-3xl font-medium">
                {alternateOptionLabels.length > 0
                  ? alternateOptionLabels[idx]
                  : el}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RegularRadioSelect;
