import React, { useState } from "react";
import Images from "assets/images";
import Chip from "components/chip/Chip";
import Card from "components/visitCommon/Card";
import Alert from "components/alerts/Alert";
import useModal from "hooks/useModal";
import {
  medicationDosageForms,
  medicationFrequencies,
  medicationPerValues,
} from "helpers/statics";

function DoseTextField({ disabled, value, setSelected, index }) {
  const [dose, setDose] = useState(value);
  return (
    <input
      type="text"
      style={{
        background: "transparent",
      }}
      value={dose}
      disabled={disabled}
      onChange={(event) => setDose(event.target.value)}
      onBlur={() => {
        setSelected((prev) => {
          return prev.map((med, idx) => {
            if (index === idx) {
              return {
                ...med,
                dose,
              };
            }
            return med;
          });
        });
      }}
    />
  );
}

function SearchInputWithChipCard({
  onClickInput,
  inputLabel,
  selected,
  setSelected,
}) {
  const { setShowModal } = useModal();
  return (
    <Card>
      <div
        onClick={onClickInput}
        className="flex items-center justify-between border border-borderColor rounded-1.6 p-6 cursor-text"
      >
        <p className="text-2xl font-bold opacity-40">{inputLabel}</p>
        <img
          className="w-8 h-8 object-contain"
          src={Images.searchIcon}
          alt={"search"}
        />
      </div>
      {selected?.length ? (
        <div className="mt-5 pt-8 px-12 border-t border-borderColor -mr-10 -ml-10">
          <div className="flex flex-wrap overflow-x-auto">
            {inputLabel === "Search Medication" ? (
              <table className="min-w-max w-full table-auto">
                <thead>
                  <tr>
                    <th className="p-4 text-2xl font-semibold text-left text-indigo">
                      Medication Name
                    </th>
                    <th className="p-4 text-2xl font-semibold text-left text-indigo">
                      Dosage
                    </th>
                    <th className="p-4 text-2xl font-semibold text-left text-indigo">
                      Form
                    </th>
                    <th className="p-4 text-2xl font-semibold text-left text-indigo">
                      Frequency
                    </th>
                    <th className="p-4 text-2xl font-semibold text-left text-indigo">
                      Per
                    </th>
                    <th className="p-4 text-2xl font-semibold text-left text-indigo">
                      Deactivate?
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selected.map((drug, index) => (
                    <tr key={drug?.name}>
                      <td className="p-4 h-9 font-semibold text-2xl border-t border-grey-600 text-indigo">
                        {drug?.name}
                      </td>
                      <td className="p-4 h-9 text-2xl border-t border-grey-600 text-indigo">
                        <div className="custom_input">
                          <DoseTextField
                            disabled={drug?.dose?.length > 0}
                            value={drug?.dose}
                            setSelected={setSelected}
                            index={index}
                          />
                        </div>
                      </td>
                      <td className="p-4 h-9 text-2xl border-t border-grey-600 text-indigo">
                        <div className="relative flex flex-col custom_input">
                          <select
                            className="relative cursor-pointer !pr-12"
                            disabled={drug?.form?.length > 0}
                            defaultValue={drug?.form}
                            onChange={(event) => {
                              setSelected((prev) => {
                                return prev.map((med, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...med,
                                      form: event.target.value,
                                    };
                                  }
                                  return med;
                                });
                              });
                            }}
                          >
                            <option value="">Select a value</option>
                            {medicationDosageForms?.map((form) => (
                              <option key={form} value={form}>
                                {form}
                              </option>
                            ))}
                          </select>
                          <img
                            src={Images.dropdown}
                            alt="chev_down"
                            className="absolute right-2 transition duration-500 ease-in-out w-10 h-10 transform rotate-0 bottom-3"
                          />
                        </div>
                      </td>
                      <td className="p-4 h-9 text-2xl border-t border-grey-600 text-indigo">
                        <div className="relative flex flex-col custom_input">
                          <select
                            className="relative cursor-pointer !pr-12"
                            disabled={drug?.frequency?.length > 0}
                            defaultValue={drug?.frequency}
                            onChange={(event) => {
                              setSelected((prev) => {
                                return prev.map((med, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...med,
                                      frequency: event.target.value,
                                    };
                                  }
                                  return med;
                                });
                              });
                            }}
                          >
                            <option value="">Select a value</option>
                            {medicationFrequencies?.map((el) => (
                              <option key={el} value={el}>
                                {el}
                              </option>
                            ))}
                          </select>
                          <img
                            src={Images.dropdown}
                            alt="chev_down"
                            className="absolute right-2 transition duration-500 ease-in-out w-10 h-10 transform rotate-0 bottom-3"
                          />
                        </div>
                      </td>
                      <td className="p-4 h-9 text-2xl border-t border-grey-600 text-indigo">
                        <div className="relative flex flex-col custom_input">
                          <select
                            className="relative cursor-pointer !pr-12"
                            disabled={drug?.per?.length > 0}
                            defaultValue={drug?.per}
                            onChange={(event) => {
                              setSelected((prev) => {
                                return prev.map((med, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...med,
                                      per: event.target.value,
                                    };
                                  }
                                  return med;
                                });
                              });
                            }}
                          >
                            <option value="">Select a value</option>
                            {medicationPerValues?.map((per) => (
                              <option key={per} value={per}>
                                {per}
                              </option>
                            ))}
                          </select>
                          <img
                            src={Images.dropdown}
                            alt="chev_down"
                            className="absolute right-2 transition duration-500 ease-in-out w-10 h-10 transform rotate-0 bottom-3"
                          />
                        </div>
                      </td>
                      <td className="p-4 h-9 text-2xl border-t border-grey-600 text-indigo">
                        <img
                          onClick={() => {
                            setShowModal({
                              state: true,
                              children: (_, onClose) => (
                                <Alert
                                  heading="Notice:"
                                  message="Don't worry! You can re-add it in your health profile if you need to in the future"
                                  primaryButtonText="Confirm"
                                  secondaryButtonText="Cancel"
                                  onPrimaryButtonClick={() => {
                                    onClose();
                                    setSelected((prev) => {
                                      return prev?.filter(
                                        (med, idx) => index !== idx && med
                                      );
                                    });
                                  }}
                                  onSecondaryButtonClick={onClose}
                                />
                              ),
                            });
                          }}
                          src={Images.deleteIcon}
                          className="mx-auto w-10 h-10 cursor-pointer"
                          alt="delete"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : inputLabel === "Search Allergy" ? (
              selected.map((el, index) => (
                <Chip
                  key={index}
                  text={
                    inputLabel === "Search Medication" ||
                    inputLabel === "Search Allergy"
                      ? el?.name
                      : el
                  }
                  className="mb-8"
                  onClear={() => {
                    setSelected((prev) => prev?.filter((elem) => elem !== el));
                  }}
                  hideDelete={true}
                />
              ))
            ) : (
              selected.map((el, index) => (
                <Chip
                  key={index}
                  text={
                    inputLabel === "Search Medication" ||
                    inputLabel === "Search Allergy"
                      ? el?.name
                      : el
                  }
                  className="mb-8"
                  onClear={() => {
                    setSelected((prev) => prev?.filter((elem) => elem !== el));
                  }}
                />
              ))
            )}
          </div>
        </div>
      ) : null}
    </Card>
  );
}

export default SearchInputWithChipCard;
