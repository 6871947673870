import React, { useEffect, useState } from "react";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";
import { femaleMedicalHistoryData } from "../../dermatology/medicalHistory/medicalHistoryData";
import { primary_care_sub_steps } from "helpers/enum";
import useToastify from "hooks/useToastify";
import Button from "components/buttons/Button";

const FemaleMedicalHistory = ({
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
}) => {
  const { showAlert } = useToastify();
  const [selected, setSelected] = useState([]);

  function handleOnSelect(el) {
    if (el === "None") {
      setSelected([el]);
    } else {
      let final = [...selected];

      if (final?.includes("None")) {
        final = final.filter((elem) => elem !== "None");
      }
      if (final?.includes(el)) {
        setSelected(final.filter((elem) => elem !== el));
      } else {
        setSelected([...final, el]);
      }
    }
  }

  useEffect(() => {
    if (incompleteData) {
      if (
        incompleteData?.history.length === 0 ||
        incompleteData?.history === ["None"]
      ) {
        setSelected(["None"]);
      } else {
        setSelected(incompleteData?.history);
      }
    }
  }, [incompleteData]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          fhistory: selected,
          allergies: incompleteData?.allergies_json,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications?.map((med) => {
                  return {
                    ...med?.details,
                    name: med?.name,
                  };
                }),
          pc_second_phase_steps: {
            ...incompleteData?.pc_second_phase_steps,
            [primary_care_sub_steps.female_medical_history]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(10);
      },
    });
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl p-10 relative overflow-x-hidden">
      <CheckBoxCard
        options={femaleMedicalHistoryData}
        selected={selected}
        onSelect={handleOnSelect}
      />
      <Button
        className="mt-10 py-4"
        disabled={selected.length === 0}
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
};

export default FemaleMedicalHistory;
