import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import FormikErrorFocus from "formik-error-focus";

import Images from "assets/images";

import Button from "components/buttons/Button";
import Chip from "components/chip/Chip";
import Header from "components/header/Header";
import Spacer from "components/Spacer/Spacer";

import { searchMedicalConditionAction } from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import useDebounce from "hooks/useDebounce";
import useToastify from "hooks/useToastify";
import { addAllergyWithInfo } from "helpers/validationSchema";
import { input_types } from "helpers/enum";
import Input from "components/inputs/Input";
import { allergyReactions } from "helpers/statics";
import RegularRadioSelect from "components/radioButtonCard/RegularRadioSelect";

export default function AddAllergyModal({ onClose, selected, setSelected }) {
  const formRef = useRef();
  const keyToSearch = "allergies";
  const [selectedLocal, setSelectedLocal] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const { showAlert } = useToastify();

  const [dataFromApi, setDataFromApi] = useState([]);

  const {
    loading,
    payload: result,
    query: callSearch,
    error: searchResultError,
  } = useApiQuery(
    searchMedicalConditionAction({
      condition: keyToSearch,
      searchText: debouncedSearchTerm,
      type: null,
    }),
    false
  );

  useEffect(() => {
    if (debouncedSearchTerm?.trim()?.length && keyToSearch) {
      callSearch();
    } else {
      setDataFromApi([]);
    }
  }, [debouncedSearchTerm, callSearch, keyToSearch]);

  useEffect(() => {
    if (searchResultError) {
      showAlert("Something went wrong", "error");
      setDataFromApi([]);
    } else {
      if (result && result?.length) {
        setDataFromApi(result?.map((el) => el));
      } else {
        setDataFromApi([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, searchResultError, showAlert]);

  useEffect(() => {
    if (selected?.length) {
      setSelectedLocal(selected);
    }
  }, [selected]);

  const removeSelectedMed = (el) => {
    setSelectedLocal((prev) => prev?.filter((elem) => elem.name !== el));
  };

  const addSelectedAllergy = (values, resetForm) => {
    setSelectedLocal((prev) => [
      ...prev,
      {
        name: values?.allergy?.name,
        brand_name: values?.allergy?.brand_name,
        allergen_id: values?.allergy?.allergen_id,
        allergen_type: values?.allergy?.allergen_type,
        is_active: values?.allergy?.is_active,
        reaction: values?.reaction,
        category: values?.category,
      },
    ]);
    setSearchText("");
    resetForm();
  };

  return (
    <div className="bg-veryLightBlue h-screen">
      <Header
        title="Select Allergies"
        leftActionView={
          <img
            onClick={onClose}
            className="w-8 cursor-pointer"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
        rightActionView={
          <p
            onClick={() => {
              setSelected(selectedLocal);
              onClose();
            }}
            className="text-2xl text-orange font-bold cursor-pointer"
          >
            Done
          </p>
        }
      />
      <div className="wrapper bg-white h-screen overflow-hidden">
        <div className="border-b border-borderColor">
          {selectedLocal?.length ? (
            <div className="border-b border-borderColor">
              <div className="pt-6 px-12">
                <h2 className="text-xl font-black tracking-widest uppercase">
                  {selectedLocal?.length} selected
                </h2>
                <div className="overflow-auto">
                  <div className="flex pt-8 pb-10">
                    {selectedLocal?.map((el, index) => (
                      <Chip
                        text={el?.name}
                        key={index}
                        onClear={removeSelectedMed}
                        hideDelete={true}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="overflow-y-auto px-0 py-8 pb-64 h-eliminateHeaderWithChipAndAddContainer bg-alternativeWhite">
          <div className="px-12">
            {
              <Formik
                innerRef={formRef}
                validationSchema={addAllergyWithInfo}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  allergy: {},
                  reaction: "",
                  category: "",
                }}
                onSubmit={(values, { resetForm }) =>
                  addSelectedAllergy(values, resetForm)
                }
              >
                {({ values, setFieldValue, touched, errors }) => (
                  <>
                    <Form>
                      <Input
                        loading
                        type={input_types.CREATABLE}
                        placeholder="Name of the allergy"
                        name="allergy"
                        label="Allergy Name"
                        id="allergy"
                        customonchange={(data) => {
                          setSearchText(data);
                        }}
                        isLoading={loading}
                        options={[
                          ...dataFromApi?.map((allergy) => ({
                            ...allergy,
                            label: allergy?.name,
                          })),
                        ]}
                        disabled={false}
                      />
                      <Spacer height={24} />
                      <Input
                        type={input_types.DROPDOWN}
                        default={"Select a value"}
                        options={allergyReactions}
                        label="Reaction"
                        id="reaction"
                        name="reaction"
                      />
                      <Spacer height={24} />
                      <div className="ml-0 flex justify-between items-baseline md:ml-4">
                        <div className="flex-1 flex flex-col space-y-2">
                          <RegularRadioSelect
                            label="Category"
                            options={[
                              "drug_or_medication_allergy",
                              "environment_or_food_allergy",
                            ]}
                            selected={values?.category}
                            setSelected={(el) => setFieldValue("category", el)}
                            alternateOptionLabels={[
                              "Medication Allergy",
                              "Environment or Food Allergy",
                            ]}
                          />
                          {touched?.category && errors?.category ? (
                            <p className="font-bold text-base text-red">
                              {errors?.category}
                            </p>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                      <Spacer height={24} />

                      <FormikErrorFocus
                        align={"bottom"}
                        ease={"linear"}
                        duration={500}
                      />
                    </Form>
                    <Spacer height={24} />
                    <Button
                      type="submit"
                      label="Add Allergy"
                      onClick={() => formRef?.current?.handleSubmit()}
                      loading={loading}
                      className={"h-18"}
                    />
                  </>
                )}
              </Formik>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
