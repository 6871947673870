import React, { useEffect, useRef } from "react";
import useHealthProfile from "hooks/useHealthProfile";
import { useHistory, useParams } from "react-router-dom";
import CSSLoader from "components/cssLoader/CSSLoader";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileQuestion from "../HealthProfileQuestion";
import HealthProfileRadio from "../HealthProfileRadio";
import { Form, Formik } from "formik";
import AddHealthProfileAllergies from "./AddHealthProfileAllergies";
import { HealthProfileAllergiesSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

const YESNO = ["Yes", "No"];

function HPAllergies() {
  const formRef = useRef();
  const history = useHistory();
  const { patient_id } = useParams();
  const { healthPayload, postHealthProfile, postingHealthProfile } =
    useHealthProfile();

  useEffect(() => {
    if (healthPayload) {
      formRef?.current?.setValues({
        isAllergic:
          healthPayload?.health_profile?.allergic === "not_allergic"
            ? "No"
            : healthPayload?.health_profile?.allergic === "is_allergic"
            ? "Yes"
            : "",
        drug_medication_allergies:
          healthPayload?.health_profile?.allergies?.filter(
            (allergy) => allergy?.category === "drug_or_medication_allergy"
          ) ?? [],
        environmental_food_allergies:
          healthPayload?.health_profile?.allergies?.filter(
            (allergy) => allergy?.category === "environment_or_food_allergy"
          ) ?? [],
        drug_or_environmental: "",
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {};
    if (values?.isAllergic === "Yes") {
      payload = {
        allergic: 2,
        health_profile_allergies_attributes:
          values?.drug_medication_allergies?.concat(
            values?.environmental_food_allergies
          ),
      };
    } else {
      payload = {
        allergic: 1,
        health_profile_allergies_attributes: [],
      };
    }
    postHealthProfile({ patientId: patient_id, payload, completed });
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        onSubmit={onSave}
        innerRef={formRef}
        initialValues={{
          isAllergic: "",
          drug_medication_allergies: [],
          environmental_food_allergies: [],
          drug_or_environmental: "",
        }}
        validationSchema={HealthProfileAllergiesSchema}
      >
        {({ setFieldValue, values, errors, submitCount }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Allergies"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="px-12 py-6 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={
                  <p>Please tell us about any allergies you may have.</p>
                }
              />
              <Spacer height={26} />
              <HealthProfileQuestion
                text={
                  "Do you have any allergies or a history of allergic reactions to anything?"
                }
              />
              <div className="flex items-center mt-8">
                {YESNO?.map((option, optionIndex) => {
                  return (
                    <div className="flex flex-1" key={optionIndex}>
                      <HealthProfileRadio
                        text={option}
                        selected={values?.isAllergic === option}
                        onClick={() => setFieldValue("isAllergic", option)}
                      />
                    </div>
                  );
                })}
              </div>
              {errors?.isAllergic && submitCount ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.isAllergic}
                </p>
              ) : null}
              {values?.isAllergic === "Yes" ? (
                <div className="pb-12">
                  <AddHealthProfileAllergies
                    setFieldValue={setFieldValue}
                    drug_medication_allergies={
                      values?.drug_medication_allergies
                    }
                    environmental_food_allergies={
                      values?.environmental_food_allergies
                    }
                  />
                  {errors?.drug_or_environmental && submitCount ? (
                    <p className="mt-12 text-base font-bold text-red">
                      {errors?.drug_or_environmental}
                    </p>
                  ) : null}
                </div>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HPAllergies;
