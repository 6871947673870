import React from "react";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import useModal from "hooks/useModal";
import HealthProfileHistoryDebounceModal from "../HealthProfileHistoryDebounceModal";
import { input_types } from "helpers/enum";
import { medicationFrequencies, medicationPerValues } from "helpers/statics";

function AddHealthProfileForMedications({
  medication_forms,
  setFieldValue,
  activeMeds,
}) {
  const { setShowModal } = useModal();
  return (
    <div>
      <h3 className="text-3xl font-medium mt-14">Medications</h3>
      {activeMeds?.map((el, idx) => {
        return (
          <div
            key={idx}
            className="pt-7 pb-9 px-9 bg-alternativeWhite rounded-2xl mt-10"
          >
            <Input
              onFocus={() => {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <HealthProfileHistoryDebounceModal
                      keyToSearch={"medications"}
                      title="Search Medications"
                      onClose={onClose}
                      selected={el?.drug_name}
                      onSelect={(val) => {
                        if (typeof val === "string") {
                          setFieldValue(`activeMeds[${idx}].drug_name`, val);
                        } else {
                          setFieldValue(
                            `activeMeds[${idx}].drug_name`,
                            val?.name
                          );
                          setFieldValue(
                            `activeMeds[${idx}].DispensableDrugId`,
                            val?.DispensableDrugId
                          );
                          setFieldValue(
                            `activeMeds[${idx}].IsObsolete`,
                            val?.IsObsolete
                          );
                          setFieldValue(`activeMeds[${idx}].NDC`, val?.NDC);
                          setFieldValue(
                            `activeMeds[${idx}].NameWithRouteDoseForm`,
                            val?.NameWithRouteDoseForm
                          );
                          setFieldValue(`activeMeds[${idx}].RXCUI`, val?.RXCUI);
                          setFieldValue(
                            `activeMeds[${idx}].RoutedDoseFormDrugId`,
                            val?.RoutedDoseFormDrugId
                          );
                          setFieldValue(
                            `activeMeds[${idx}].Strength`,
                            val?.Strength
                          );
                        }
                      }}
                    />
                  ),
                });
              }}
              disabled={
                activeMeds[idx]?.id && activeMeds[idx]?.drug_name?.length
              }
              label="Medication Name"
              id={`activeMeds[${idx}].drug_name`}
              name={`activeMeds[${idx}].drug_name`}
            />
            <Spacer height={23} />
            <Input
              label="Dosage"
              id={`activeMeds[${idx}].dose`}
              name={`activeMeds[${idx}].dose`}
            />
            <Spacer height={23} />
            <Input
              disabled={activeMeds[idx]?.id && activeMeds[idx]?.form?.length}
              type={input_types.DROPDOWN}
              default={"Select a value"}
              options={medication_forms}
              label="Form"
              id={`activeMeds[${idx}].form`}
              name={`activeMeds[${idx}].form`}
            />
            {el?.form === "Other" ? (
              <>
                <Spacer height={23} />
                <Input
                  label="Other Form"
                  id={`activeMeds[${idx}].other_form`}
                  name={`activeMeds[${idx}].other_form`}
                />
              </>
            ) : null}
            <Spacer height={23} />
            <Input
              disabled={
                activeMeds[idx]?.id && activeMeds[idx]?.frequency?.length
              }
              type={input_types.DROPDOWN}
              default={"Select a value"}
              options={medicationFrequencies}
              label="Frequency"
              id={`activeMeds[${idx}].frequency`}
              name={`activeMeds[${idx}].frequency`}
            />
            <Spacer height={23} />
            <Input
              disabled={activeMeds[idx]?.id && activeMeds[idx]?.per?.length}
              type={input_types.DROPDOWN}
              default={"Select a value"}
              options={medicationPerValues}
              label="Per"
              id={`activeMeds[${idx}].per`}
              name={`activeMeds[${idx}].per`}
            />
            {activeMeds[idx]?.id ? (
              <p
                onClick={() => {
                  if (activeMeds[idx]?.status === "active") {
                    setFieldValue(
                      "activeMeds",
                      activeMeds?.map((med, index) => {
                        if (index === idx) {
                          return {
                            ...med,
                            status: "inactive",
                          };
                        }
                        return med;
                      })
                    );
                  }
                }}
                className={`${
                  activeMeds[idx]?.status === "active"
                    ? "underline cursor-pointer "
                    : ""
                }text-red text-center font-light text-2xl mt-12`}
              >
                {activeMeds[idx]?.status === "active"
                  ? "Deactivate"
                  : "Deactivated"}
              </p>
            ) : (
              <p
                onClick={() => {
                  setFieldValue(
                    "activeMeds",
                    activeMeds?.filter((_, index) => {
                      if (index === idx) {
                        return false;
                      }
                      return true;
                    })
                  );
                }}
                className="text-red text-center font-light text-2xl underline mt-12 cursor-pointer"
              >
                Delete
              </p>
            )}
          </div>
        );
      })}
      <p
        onClick={() => {
          setFieldValue("activeMeds", [
            ...activeMeds,
            {
              drug_name: "",
              dose: "",
              frequency: "",
              per: "",
              form: "",
              other_form: "",
              status: "active",
            },
          ]);
        }}
        className="text-2xl font-light underline text-orange mt-14 cursor-pointer"
      >
        Add Medications
      </p>
    </div>
  );
}

export default AddHealthProfileForMedications;
