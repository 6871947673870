import React, { useEffect, useState } from "react";
import { getVisitSymptomsAction } from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useDispatch } from "react-redux";
import { ped_sub_steps } from "helpers/enum";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import Button from "components/buttons/Button";
import useToastify from "hooks/useToastify";
import SymptomChip from "../../common/SymptomChip";

function Symptoms({
  incompleteData,
  basicDetails,
  updateVisit,
  updatingVisit,
  setActiveIndex,
}) {
  const dispatch = useDispatch();
  const [selectedSymptoms, setSelectedSymptoms] = useState(
    incompleteData?.pediatric_symptoms || []
  );
  const { showAlert } = useToastify();

  const {
    payload: symptomsFromApi,
    loading: gettingSymptoms,
    query: pcSymptomsQuery,
  } = useApiQuery(
    getVisitSymptomsAction({
      speciality: 4,
      gender: basicDetails?.gender,
    }),
    false
  );

  useEffect(() => {
    if (basicDetails?.gender) {
      pcSymptomsQuery();
    }
  }, [basicDetails, pcSymptomsQuery]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          pediatric_symptoms: selectedSymptoms,
          allergies: incompleteData?.allergies_json,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications?.map((med) => {
                  return {
                    ...med?.details,
                    name: med?.name,
                  };
                }),
          pediatric_second_phase_steps: {
            ...incompleteData?.pediatric_second_phase_steps,
            [ped_sub_steps.symptoms]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        dispatch(
          pedVisitFlowReducers.symptoms({
            value: selectedSymptoms,
          })
        );
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(7);
        nextAnimation();
      },
    });
  }

  function onSelectSymptom(value, currentCategory) {
    if (
      selectedSymptoms?.find(
        (categ) => categ?.category === currentCategory?.related_to
      )
    ) {
      let selectedSymptomsCopy = [...selectedSymptoms];
      setSelectedSymptoms(
        selectedSymptomsCopy
          ?.map((categ) => {
            if (categ?.category === currentCategory?.related_to) {
              if (categ?.symptoms?.includes(value)) {
                categ = {
                  ...categ,
                  symptoms: categ?.symptoms?.filter((val) => val !== value),
                };
              } else {
                categ = {
                  ...categ,
                  symptoms: [...categ?.symptoms, value],
                };
              }
            }
            return categ;
          })
          ?.filter((vl) => vl?.symptoms?.length)
      );
    } else {
      setSelectedSymptoms((prev) => [
        ...prev,
        { category: currentCategory?.related_to, symptoms: [value] },
      ]);
    }
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl px-10 pb-10 relative overflow-x-hidden">
      {gettingSymptoms ? (
        <div className={"h-40 flex items-center justify-center"}>
          <CSSLoader className={"w-16 h-16"} color={"orange"} />
        </div>
      ) : (
        symptomsFromApi?.map((elem) => {
          return (
            <div className="mb-8" key={elem?.id}>
              <p className="text-3xl font-medium">{elem?.related_to}</p>
              <div className="mt-4 flex flex-wrap items-baseline space-y-4">
                {elem?.symptom_opts.map((el, idx) => {
                  return (
                    <SymptomChip
                      key={idx}
                      selected={selectedSymptoms
                        ?.find(
                          (element) => element?.category === elem?.related_to
                        )
                        ?.symptoms.includes(el)}
                      onSelect={() => onSelectSymptom(el, elem)}
                      symptomText={el}
                    />
                  );
                })}
              </div>
            </div>
          );
        })
      )}
      <Button
        disabled={selectedSymptoms.length === 0}
        className="py-4 mt-10"
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
}

export default Symptoms;
