const checkoutDisclaimer = [
  {
    icon: "secure-user",
    title: "Data Privacy",
    text: "Your personal data is kept private and secure. We follow strict privacy rules to protect your information.",
  },
  {
    icon: "secure-shield",
    title: "Secured Transactions",
    text: "Rest assured, your health-related are private and secure here, maintaining confidentially of your consultations.",
  },
  {
    icon: "secure-consultation",
    title: "Confidential Consultations",
    text: "Your payment transactions are protected by industry-standard encryption, assuring the safety of your financial details.",
  },
];

const disclaimer = [
  "We don't charge you until after you've been evaluated by one of our doctors.",
  "Please provide your credit card information to secure your appointment.",
  "We will verify your insurance and benefits once we have your health insurance.",
];

const medicationDosageForms = [
  "Capsule",
  "Cream",
  "Foam",
  "Gel",
  "Implant",
  "Injection",
  "Liquid",
  "Lotion",
  "Oil",
  "Ointment",
  "Patch",
  "Ring",
  "Shampoo",
  "Tablet",
  "Other",
];

const medicationFrequencies = [
  "Once",
  "Twice",
  "Three",
  "Four",
  "Five",
  "Six",
  "PRN",
  "Other",
];

const medicationPerValues = [
  "Hour",
  "Day",
  "Week",
  "Month",
  "Year",
  "PRN",
  "Other",
];

const allergyReactions = [
  "Anaphylaxis",
  "Brachycardia",
  "Chest Pain",
  "Conjunctivitis",
  "Cough",
  "Diarrhea",
  "Difficulty speaking or swallowing",
  "Dizziness/Lightheadedness",
  "Facial swelling",
  "Hives",
  "Irregular Heartbeat",
  "Itchiness",
  "Loss of consciousness",
  "Nausea",
  "Rash",
  "Respiratory Distress",
  "Runny nose",
  "Shortness of breath",
  "Tachycardia",
  "Tongue swelling",
  "Vomiting Wheezing",
];

export {
  checkoutDisclaimer,
  disclaimer,
  medicationDosageForms,
  medicationFrequencies,
  medicationPerValues,
  allergyReactions,
};
