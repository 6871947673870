import React, { useEffect } from "react";
import { Form, Formik } from "formik";

import AudioRecorder from "components/audioRecorder/AudioRecorder";
import MultiLineInput from "components/inputs/MultiLineInput";
import Spacer from "components/Spacer/Spacer";
import { useDispatch } from "react-redux";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import { useRef } from "react";
import { useApiMutation } from "hooks/useApiMutation";
import { uploadPhotoIdToS3Action } from "api/actions/ImageUploadActions";
import useToastify from "hooks/useToastify";
import { ped_sub_steps } from "helpers/enum";
import CSSLoader from "components/cssLoader/CSSLoader";
import { AdditionalDetailsSchema } from "helpers/validationSchema";
import { getAudioRecordUrlAction } from "api/actions/VisitActions";
import Button from "components/buttons/Button";

function AdditionalInfo({
  incompleteData,
  userDetails,
  updateVisit,
  updatingVisit,
  setActiveIndex,
}) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();

  const { mutate: getPresignedUrl, loading: gettingPreSignedUrl } =
    useApiMutation(getAudioRecordUrlAction);
  const { mutate: uploadAudioToS3, loading: uploadingAudio } = useApiMutation(
    uploadPhotoIdToS3Action
  );

  useEffect(() => {
    dispatch(
      pedVisitFlowReducers.additional_info({
        isValid: true,
      })
    );
  }, [dispatch]);

  function updateVisitApi(additional_info, recorded_url) {
    updateVisit({
      payload: {
        case: {
          additional_info: additional_info,
          additional_info_recording: recorded_url ?? null,
          allergies: incompleteData?.allergies_json,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications?.map((med) => {
                  return {
                    ...med?.details,
                    name: med?.name,
                  };
                }),
          pediatric_second_phase_steps: {
            ...incompleteData?.pediatric_second_phase_steps,
            [ped_sub_steps.additional_info]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(userDetails?.gender === "female" ? 11 : 10);
        dispatch(
          pedVisitFlowReducers.additional_info({
            value: {
              additional_info: additional_info,
              additional_info_recording: recorded_url ?? null,
            },
          })
        );
      },
    });
  }

  async function uploadAudio(additional_details, blob_url) {
    try {
      let blob = await fetch(blob_url).then((r) => r.blob());
      const presignedResult = await getPresignedUrl({
        caseId: incompleteData?.id,
      });
      if (presignedResult) {
        const uploadToS3Result = await uploadAudioToS3({
          endpoint: presignedResult?.payload?.presigned_url,
          payload: blob,
          headers: {
            "Content-Type": "audio/mp4",
          },
        });
        if (uploadToS3Result && uploadToS3Result?.error === false) {
          showAlert("Audio uploaded successfully.", "success");
          updateVisitApi(
            additional_details,
            presignedResult?.payload?.presigned_url?.split("?")[0]
          );
        } else {
          showAlert("Upload failed!", "error");
        }
      }
    } catch (error) {
      showAlert("Something went wrong! Please try again.", "error");
    }
  }

  async function onFormSubmit(values) {
    if (values?.record_url?.length && !values?.record_url?.startsWith("http")) {
      uploadAudio(values?.additional_details, values?.record_url);
    } else {
      updateVisitApi(values?.additional_details, values?.record_url);
    }
  }
  return (
    <div className="bg-white shadow-card rounded-b-4xl p-10 relative overflow-x-hidden">
      <Formik
        innerRef={formRef}
        onSubmit={onFormSubmit}
        initialValues={{
          record_url: incompleteData?.additional_info_recording,
          additional_details: incompleteData?.additional_info || "",
        }}
        validationSchema={AdditionalDetailsSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <label className="font-bold text-2xl text-indigo text-opacity-50">
              Record Audio (optional)
            </label>
            <Spacer height={20} />
            <AudioRecorder
              onClickDelete={() => setFieldValue("record_url", "")}
              audioResult={values?.record_url}
              setAudioResult={(url) => setFieldValue("record_url", url)}
            />
            <Spacer height={20} />
            <MultiLineInput
              label={"Write Details (optional)"}
              id={"additional_details"}
              name={"additional_details"}
              lablel="Type something.."
            />
            <Button
              className="py-4 mt-10"
              loading={updatingVisit}
              label="Next"
              type="submit"
            />
          </Form>
        )}
      </Formik>
      {gettingPreSignedUrl || uploadingAudio ? (
        <div className="absolute w-full h-full top-0 left-0 bg-alternativeWhite bg-opacity-80 flex items-center justify-center">
          <CSSLoader className="w-12 h-12" color="orange" />
          <p className="text-3xl font-bold">
            Please wait while we upload your audio recording.
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default AdditionalInfo;
