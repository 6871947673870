import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import FormikErrorFocus from "formik-error-focus";

import Images from "assets/images";

import Button from "components/buttons/Button";
import Chip from "components/chip/Chip";
import Header from "components/header/Header";
import Spacer from "components/Spacer/Spacer";

import { searchMedicalConditionAction } from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import useDebounce from "hooks/useDebounce";
import useToastify from "hooks/useToastify";
import { addMedicationsWithInfo } from "helpers/validationSchema";
import { input_types } from "helpers/enum";
import Input from "components/inputs/Input";
import {
  medicationDosageForms,
  medicationFrequencies,
  medicationPerValues,
} from "helpers/statics";

export default function AddMedicationModal({ onClose, selected, setSelected }) {
  const formRef = useRef();
  const keyToSearch = "medications";
  const [selectedLocal, setSelectedLocal] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const { showAlert } = useToastify();

  const [dataFromApi, setDataFromApi] = useState([]);

  const {
    loading,
    payload: result,
    query: callSearch,
    error: searchResultError,
  } = useApiQuery(
    searchMedicalConditionAction({
      condition: keyToSearch,
      searchText: debouncedSearchTerm,
      type: null,
    }),
    false
  );

  useEffect(() => {
    if (debouncedSearchTerm?.trim()?.length && keyToSearch) {
      callSearch();
    } else {
      setDataFromApi([]);
    }
  }, [debouncedSearchTerm, callSearch, keyToSearch]);

  useEffect(() => {
    if (searchResultError) {
      showAlert("Something went wrong", "error");
      setDataFromApi([]);
    } else {
      if (result && result?.length) {
        setDataFromApi(result?.map((el) => el));
      } else {
        setDataFromApi([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, searchResultError, showAlert]);

  useEffect(() => {
    if (selected?.length) {
      setSelectedLocal(selected);
    }
  }, [selected]);

  const removeSelectedMed = (el) => {
    setSelectedLocal((prev) => prev?.filter((elem) => elem.name !== el));
  };

  const addSelectedMed = (values, resetForm) => {
    setSelectedLocal((prev) => [
      ...prev,
      {
        dose: values?.dose,
        frequency: values?.frequency,
        per: values?.per,
        form: values?.form,
        other_form: values?.other_form,
        DispensableDrugId: values?.med?.DispensableDrugId,
        RoutedDoseFormDrugId: values?.med?.RoutedDoseFormDrugId,
        NDC: values?.med?.NDC,
        RXCUI: values?.med?.RXCUI,
        NameWithRouteDoseForm: values?.med?.NameWithRouteDoseForm,
        Strength: values?.med?.Strength,
        IsObsolete: values?.med?.IsObsolete,
        name: values?.med?.name,
      },
    ]);
    setSearchText("");
    resetForm();
  };

  return (
    <div className="bg-veryLightBlue h-screen">
      <Header
        title="Select Medications"
        leftActionView={
          <img
            onClick={onClose}
            className="w-8 cursor-pointer"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
        rightActionView={
          <p
            onClick={() => {
              setSelected(selectedLocal);
              onClose();
            }}
            className="text-2xl text-orange font-bold cursor-pointer"
          >
            Done
          </p>
        }
      />
      <div className="wrapper bg-white h-screen overflow-hidden">
        <div className="border-b border-borderColor">
          {selectedLocal?.length ? (
            <div className="border-b border-borderColor">
              <div className="pt-6 px-12">
                <h2 className="text-xl font-black tracking-widest uppercase">
                  {selectedLocal?.length} selected
                </h2>
                <div className="overflow-auto">
                  <div className="flex pt-8 pb-10">
                    {selectedLocal?.map((el, index) => (
                      <Chip
                        text={el?.name}
                        key={index}
                        onClear={removeSelectedMed}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="overflow-y-auto px-0 py-8 pb-64 h-eliminateHeaderWithChipAndAddContainer bg-alternativeWhite">
          <div className="px-12">
            {
              <Formik
                innerRef={formRef}
                validationSchema={addMedicationsWithInfo}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  med: {},
                  dose: "",
                  frequency: "",
                  per: "",
                  form: "",
                  other_form: "",
                }}
                onSubmit={(values, { resetForm }) =>
                  addSelectedMed(values, resetForm)
                }
              >
                {({ values }) => (
                  <>
                    <Form>
                      <Input
                        loading
                        type={input_types.CREATABLE}
                        placeholder="Name of the medication"
                        name="med"
                        label="Medication Name"
                        id="med"
                        customonchange={(data) => {
                          setSearchText(data);
                        }}
                        isLoading={loading}
                        options={[
                          ...dataFromApi?.map((med) => ({
                            ...med,
                            label: med?.name,
                          })),
                        ]}
                        disabled={false}
                      />
                      <Spacer height={24} />
                      <Input name="dose" label="Dosage" id="dose" />
                      <Spacer height={24} />
                      <Input
                        type={input_types.DROPDOWN}
                        default={"Select a value"}
                        options={medicationDosageForms}
                        label="Form"
                        id="form"
                        name="form"
                      />
                      {values?.form === "Other" ? (
                        <>
                          <Spacer height={24} />
                          <Input
                            label="Other form"
                            id="other_form"
                            name="other_form"
                          />
                        </>
                      ) : null}
                      <Spacer height={24} />
                      <Input
                        type={input_types.DROPDOWN}
                        default="Select a value"
                        options={medicationFrequencies}
                        label="Frequency"
                        id="frequency"
                        name="frequency"
                      />
                      <Spacer height={24} />
                      <Input
                        type={input_types.DROPDOWN}
                        default={"Select a value"}
                        options={medicationPerValues}
                        label="Per"
                        id="per"
                        name="per"
                      />

                      <FormikErrorFocus
                        align={"bottom"}
                        ease={"linear"}
                        duration={500}
                      />
                    </Form>
                    <Spacer height={24} />
                    <Button
                      type="submit"
                      label="Add Medication"
                      onClick={() => formRef?.current?.handleSubmit()}
                      loading={loading}
                      className={"h-18"}
                    />
                  </>
                )}
              </Formik>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
