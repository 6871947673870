import React from "react";

import "./Input.scss";
import ReactPhoneInput from "react-phone-input-2";

import { useField } from "formik";
import Images from "assets/images";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { input_types } from "helpers/enum";
import DatePicker from "react-date-picker";
import { isMobile } from "react-device-detect";
import moment from "moment";
import CSSLoader from "components/cssLoader/CSSLoader";
import { OTPInput } from "components/OTPInput/OTPInput";
import classNames from "classnames";

const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <img src={Images.closeIcon} alt="clear input" className="w-6 h-6" />;
    </div>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={Images.dropdown}
        alt="chev_down"
        className="w-10 h-10 transition duration-500 ease-in-out transform rotate-0"
      />
    </components.DropdownIndicator>
  );
};

function Input({ type, label, customonchange, customonblur, ...rest }) {
  const [field, meta] = useField(rest);

  if (type === input_types.PHONE) {
    return (
      <div className="flex flex-col custom_input">
        <label
          className="text-2xl font-bold text-opacity-50 text-indigo"
          style={rest?.labelstyles}
          htmlFor={rest?.id}
        >
          {label}
        </label>
        <ReactPhoneInput
          countryCodeEditable={false}
          inputClass={classNames(
            rest.className,
            meta.touched && meta.error && "invalid"
          )}
          {...rest}
          className="" //InputClass and ClassName are overlapping.
          country={"us"}
        />
        {meta.touched && meta.error ? (
          <p className="mt-2 text-base font-bold text-red">{meta.error}</p>
        ) : null}
      </div>
    );
  } else if (type === input_types.DROPDOWN && rest?.options?.length) {
    return (
      <>
        <div className="relative flex flex-col custom_input">
          <label
            className={`text-2xl font-bold text-opacity-50 text-indigo mb-2 ${
              rest.required && "required-field"
            }`}
            style={rest?.labelstyles}
            htmlFor={rest?.id}
          >
            {label}
          </label>
          <select
            className={`relative cursor-pointer pr-12 ${
              !field?.value?.length ? "opacity-50" : "opacity-100"
            }`}
            {...field}
            {...rest}
            onChange={(event) => {
              field?.onChange(event);
              if (customonchange) {
                customonchange(event?.target?.value);
              }
            }}
          >
            {rest?.default ? <option value="">{rest?.default}</option> : null}
            {rest?.options?.map((el, index) => (
              <option key={index} value={el}>
                {el}
              </option>
            ))}
          </select>
          <img
            src={Images.dropdown}
            alt="chev_down"
            className={`absolute right-2 transition duration-500 ease-in-out w-10 h-10 transform rotate-0 bottom-3`}
          />
        </div>
        {meta.touched && meta.error ? (
          <p className="mt-2 text-base font-bold text-red">{meta.error}</p>
        ) : null}
      </>
    );
  } else if (type === input_types.SELECT) {
    return (
      <div className="flex flex-col">
        <label
          className={`text-2xl font-bold text-opacity-50 text-indigo mb-2 ${
            rest.required && "required-field"
          }`}
          style={rest?.labelstyles}
          htmlFor={rest?.id}
        >
          {label}
        </label>
        <Select
          styles={{
            menu: (provider) => ({ ...provider }),
            option: (provider) => ({
              ...provider,
              fontSize: "1.4rem",
              fontWeight: 500,
            }),
            control: () => ({
              display: "flex",
              border: "2px solid rgb(235, 235, 235)",
              paddingLeft: 0,
              borderRadius: "8px",
            }),
            singleValue: (provider) => ({
              ...provider,
              fontSize: "1.875rem",
              fontWeight: 500,
              color: "#101347",
            }),
            valueContainer: (provider) => ({
              ...provider,
              paddingLeft: 4,
            }),
            input: (provider) => ({
              ...provider,
              paddingLeft: 0,
              fontSize: "1.875rem",
              color: "#101347",
              "& input": {
                fontWeight: 500,
              },
            }),
          }}
          name={rest?.name}
          id={rest?.id}
          isDisabled={rest?.disabled}
          placeholder={rest?.placeholder}
          blurInputOnSelect={true}
          isClearable={true}
          isSearchable={true}
          onChange={(data) => {
            let event = {
              target: { name: rest?.name, value: data?.value ?? "" },
            };
            field?.onChange(event);
          }}
          onBlur={() => {
            field?.onBlur({ target: { name: rest?.name } });
          }}
          value={{
            label: field?.value,
            value: field?.value,
          }}
          options={rest?.options}
          components={{ ClearIndicator, DropdownIndicator }}
          onInputChange={(data) => {
            if (customonchange) {
              customonchange(data);
            }
          }}
          {...rest}
        />
        {meta.touched && meta.error ? (
          <p className="mt-2 text-base font-bold text-red">{meta.error}</p>
        ) : null}
      </div>
    );
  } else if (type === input_types.CREATABLE) {
    return (
      <div className="flex flex-col">
        <label
          className={`text-2xl font-bold text-opacity-50 text-indigo mb-2 ${
            rest.required && "required-field"
          }`}
          style={rest?.labelstyles}
          htmlFor={rest?.id}
        >
          {label}
        </label>
        <CreatableSelect
          styles={{
            menu: (provider) => ({ ...provider }),
            option: (provider) => ({
              ...provider,
              fontSize: "1.4rem",
              fontWeight: 500,
            }),
            control: () => ({
              display: "flex",
              border: "2px solid rgb(235, 235, 235)",
              paddingLeft: 0,
              borderRadius: "8px",
            }),
            singleValue: (provider) => ({
              ...provider,
              fontSize: "1.875rem",
              fontWeight: 500,
              color: "#101347",
            }),
            valueContainer: (provider) => ({
              ...provider,
              paddingLeft: 4,
            }),
            input: (provider) => ({
              ...provider,
              paddingLeft: 0,
              fontSize: "1.875rem",
              color: "#101347",
              "& input": {
                fontWeight: 500,
              },
            }),
          }}
          name={rest?.name}
          id={rest?.id}
          isDisabled={rest?.disabled}
          placeholder={rest?.placeholder}
          blurInputOnSelect={true}
          isClearable={true}
          isSearchable={true}
          onChange={(data) => {
            let event = {
              target: {
                name: rest?.name,
                value: data?.__isNew__
                  ? {
                      ...data,
                      name: data?.label,
                    }
                  : data ?? "",
              },
            };
            field?.onChange(event);
          }}
          onBlur={() => {
            field?.onBlur({ target: { name: rest?.name } });
          }}
          value={{
            label: field?.value?.name,
            value: field?.value,
          }}
          options={rest?.options}
          components={{ ClearIndicator, DropdownIndicator }}
          onInputChange={(data) => {
            if (customonchange) {
              customonchange(data);
            }
          }}
          {...rest}
        />
        {meta.touched && meta.error ? (
          <p className="mt-2 text-base font-bold text-red">{meta.error}</p>
        ) : null}
      </div>
    );
  } else if (type === input_types.DATEPICKER) {
    return (
      <div className="flex flex-col custom_input">
        <label
          className={`font-bold text-2xl text-indigo text-opacity-50`}
          style={rest?.labelstyles}
        >
          {label}
        </label>
        {isMobile ? (
          <input className={"text-left"} type={"date"} {...field} {...rest} />
        ) : (
          <DatePicker
            name={rest?.name}
            onCalendarOpen={() => {
              field?.onBlur({ target: { name: rest?.name } });
            }}
            className={"w-full date-picker-class"}
            calendarClassName={"date-picker-calendar-class"}
            value={
              field?.value?.length
                ? moment(field?.value, "YYYY-MM-DD").toDate()
                : null
            }
            onChange={(date) => {
              let event = {
                target: {
                  name: rest?.name,
                  value: date ? moment(date).format("YYYY-MM-DD") : "",
                },
              };
              field?.onChange(event);
            }}
            format={"MM/dd/y"}
            yearPlaceholder={"YYYY"}
            monthPlaceholder={"MM"}
            dayPlaceholder={"DD"}
            maxDate={new Date(rest?.max)}
            minDate={new Date(rest?.min)}
          />
        )}
        {meta.touched && meta.error ? (
          <p className="mt-2 text-base font-bold text-red">{meta.error}</p>
        ) : null}
      </div>
    );
  } else if (type === input_types.UNIT) {
    return (
      <div className="flex flex-col custom_input">
        <label
          className={`font-bold text-2xl text-indigo text-opacity-50 ${
            rest.required && "required-field"
          }`}
          style={rest?.labelstyles}
          htmlFor={rest?.id}
        >
          {label}
        </label>
        <div className="flex mt-2">
          <input
            type="number"
            {...field}
            {...rest}
            className={classNames(
              rest.className,
              meta.touched && meta.error && "invalid"
            )}
            onBlur={(event) => {
              field?.onBlur(event);
              if (customonblur) {
                customonblur();
              }
            }}
          />
          <div className="flex items-center px-4">
            <p className="text-2xl font-bold text-black">{rest.unit}</p>
          </div>
        </div>
        <p
          className={`font-bold text-base mt-2 text-red ${
            meta.touched && meta.error ? "text-opacity-100" : "text-opacity-0"
          }`}
        >
          {meta.error ?? "Field is required"}
        </p>
      </div>
    );
  } else if (type === input_types.WITHLOADER) {
    return (
      <div className="flex flex-col">
        <label
          className={`font-bold text-2xl text-indigo text-opacity-50`}
          style={rest?.labelstyles}
          htmlFor={rest?.id}
        >
          {label}
        </label>
        <div
          className={classNames(
            "flex items-center custom_pass_field mt-2",
            meta.touched && meta.error && "custom_pass_field_invalid"
          )}
        >
          {/* custom_pass_field follows new design standards. */}
          <input
            type={type ?? "text"}
            {...field}
            {...rest}
            onBlur={(event) => {
              field?.onBlur(event);
              if (customonblur) {
                customonblur();
              }
            }}
          />
          {rest.loading ? (
            <div className="flex items-center h-16 border-b-2 border-solid md:border-b-0">
              <CSSLoader className="w-8 h-8 ml-2" color="orange" />
            </div>
          ) : (
            <div />
          )}
        </div>
        {meta.touched && meta.error ? (
          <p className="mt-2 text-base font-bold text-red">{meta.error}</p>
        ) : null}
      </div>
    );
  } else if (type === input_types.OTP) {
    return (
      <div className="flex flex-col">
        <div className="flex items-center">
          <OTPInput
            autoFocus
            {...rest}
            className={classNames(rest.className)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col custom_input">
        <label
          className={`font-bold text-2xl text-indigo text-opacity-50 mb-2 ${
            rest.required && "required-field"
          }`}
          style={rest?.labelstyles}
          htmlFor={rest?.id}
        >
          {label}
        </label>
        <input
          type={type ?? "text"}
          {...field}
          {...rest}
          className={classNames(
            rest.className,
            rest.disabled ? "cursor-not-allowed" : "",
            meta.touched && meta.error && "invalid"
          )}
          style={{
            background: rest?.disabled ? "#f2f2f2" : "transparent",
          }}
          onBlur={(event) => {
            field?.onBlur(event);
            if (customonblur) {
              customonblur();
            }
          }}
        />
        {meta.touched && meta.error ? (
          <p id="otp-error" className="mt-2 text-lg font-medium text-errorRed">
            {meta.error}
          </p>
        ) : null}
      </div>
    );
  }
}

export default Input;
