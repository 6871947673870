import Button from "components/buttons/Button";
import CSSLoader from "components/cssLoader/CSSLoader";
import PharmacyMapView from "components/mapView/PharmacyMapView";
import PharmacyCard from "components/pharmacyCard/PharmacyCard";
import { ped_sub_steps } from "helpers/enum";
import useModal from "hooks/useModal";
import useToastify from "hooks/useToastify";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";

function SelectPharmacy({
  incompleteData,
  updateVisit,
  updatingVisit,
  selectedPharmacy,
  setSelectedPharmacy,
  setActiveIndex,
}) {
  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { showAlert } = useToastify();

  useEffect(() => {
    if (selectedPharmacy) {
      dispatch(
        pedVisitFlowReducers.select_pharmacy({
          isValid: true,
        })
      );
    } else {
      dispatch(
        pedVisitFlowReducers.select_pharmacy({
          isValid: false,
        })
      );
    }
  }, [dispatch, selectedPharmacy]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          pharmacy_details: {
            pharmacy_id: selectedPharmacy?.pharmacy_id,
            address: selectedPharmacy?.address,
          },
          allergies: incompleteData?.allergies_json,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications?.map((med) => {
                  return {
                    ...med?.details,
                    name: med?.name,
                  };
                }),
          pediatric_second_phase_steps: {
            ...incompleteData?.pediatric_second_phase_steps,
            [ped_sub_steps.select_pharmacy]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        dispatch(
          pedVisitFlowReducers.select_pharmacy({
            value: {
              pharmacy_id: selectedPharmacy?.pharmacy_id,
              address: selectedPharmacy?.address,
            },
          })
        );
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(6);
        nextAnimation();
      },
    });
  }
  return (
    <div className="bg-white shadow-card rounded-b-4xl p-10 relative overflow-x-hidden">
      {updatingVisit ? (
        <div className={"h-40 flex items-center justify-center"}>
          <CSSLoader className={"w-16 h-16"} color={"orange"} />
        </div>
      ) : (
        <>
          <PharmacyCard
            pharmacyAddess={selectedPharmacy?.address}
            onClickEdit={() => {
              if (updatingVisit) {
                return null;
              } else {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <PharmacyMapView
                      onClose={onClose}
                      onSelectPharmacy={(pharmacy) => {
                        setSelectedPharmacy(pharmacy);
                      }}
                    />
                  ),
                  data: null,
                });
              }
            }}
          />
          <Button
            disabled={
              incompleteData?.pharmacy_details === selectedPharmacy ||
              !selectedPharmacy?.pharmacy_id
            }
            className="mt-10 py-4"
            loading={updatingVisit}
            label="Next"
            onClick={onRightButtonClick}
          />
        </>
      )}
    </div>
  );
}

export default SelectPharmacy;
