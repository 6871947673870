import React from "react";
import moment from "moment";

import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import useModal from "hooks/useModal";
import HealthProfileHistoryDebounceModal from "../HealthProfileHistoryDebounceModal";
import { input_types } from "helpers/enum";
import { allergyReactions } from "helpers/statics";

function AddHealthProfileAllergies({
  drug_medication_allergies,
  environmental_food_allergies,
  setFieldValue,
}) {
  const { setShowModal } = useModal();
  return (
    <div>
      <h3 className="text-3xl font-medium mt-14">Medication Allergy</h3>
      {drug_medication_allergies?.map((el, idx) => {
        return (
          <div
            key={idx}
            className="pt-7 pb-9 px-9 bg-alternativeWhite rounded-2xl mt-10"
          >
            <Input
              onFocus={() => {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <HealthProfileHistoryDebounceModal
                      keyToSearch={"allergies"}
                      title="Search Allergies"
                      onClose={onClose}
                      selected={el?.name}
                      onSelect={(val) => {
                        if (typeof val === "string") {
                          setFieldValue(
                            `drug_medication_allergies[${idx}].name`,
                            val
                          );
                        } else {
                          setFieldValue(
                            `drug_medication_allergies[${idx}].name`,
                            val?.name
                          );
                          setFieldValue(
                            `drug_medication_allergies[${idx}].brand_name`,
                            val?.brand_name
                          );
                          setFieldValue(
                            `drug_medication_allergies[${idx}].allergen_id`,
                            val?.allergen_id
                          );
                          setFieldValue(
                            `drug_medication_allergies[${idx}].allergen_type`,
                            val?.allergen_type
                          );
                          setFieldValue(
                            `drug_medication_allergies[${idx}].is_active`,
                            val?.is_active
                          );
                        }
                      }}
                    />
                  ),
                });
              }}
              label="Allergic to"
              id={`drug_medication_allergies[${idx}].name`}
              name={`drug_medication_allergies[${idx}].name`}
              disabled={drug_medication_allergies[idx].id}
            />
            <Spacer height={23} />
            <Input
              type={input_types.DROPDOWN}
              default={"Select a value"}
              options={allergyReactions}
              label="Reaction"
              id={`drug_medication_allergies[${idx}].reaction`}
              name={`drug_medication_allergies[${idx}].reaction`}
              disabled={drug_medication_allergies[idx].id}
            />
            {drug_medication_allergies[idx]?.reported_by ? (
              <p className="mt-8 font-semibold text-2xl">
                Reported by {drug_medication_allergies[idx].reported_by} on{" "}
                {moment(drug_medication_allergies[idx]?.reported_at).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </p>
            ) : (
              <p
                onClick={() => {
                  setFieldValue(
                    "drug_medication_allergies",
                    drug_medication_allergies?.filter(
                      (_, index) => index !== idx
                    )
                  );
                }}
                className="text-red text-center font-light text-2xl underline mt-12 cursor-pointer"
              >
                Remove
              </p>
            )}
          </div>
        );
      })}
      <p
        onClick={() => {
          setFieldValue("drug_medication_allergies", [
            ...drug_medication_allergies,
            { name: "", reaction: "", category: "drug_or_medication_allergy" },
          ]);
        }}
        className="text-2xl font-light underline text-orange mt-14 cursor-pointer"
      >
        Add Medication Allergy
      </p>
      <h3 className="text-3xl font-medium mt-14">
        Environmental / Food Allergy
      </h3>
      {environmental_food_allergies?.map((el, idx) => {
        return (
          <div
            key={idx}
            className="pt-7 pb-9 px-9 bg-alternativeWhite rounded-2xl mt-10"
          >
            <Input
              onFocus={() => {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <HealthProfileHistoryDebounceModal
                      keyToSearch={"allergies"}
                      title="Search Allergies"
                      onClose={onClose}
                      selected={el?.name}
                      onSelect={(val) => {
                        if (typeof val === "string") {
                          setFieldValue(
                            `environmental_food_allergies[${idx}].name`,
                            val
                          );
                        } else {
                          setFieldValue(
                            `environmental_food_allergies[${idx}].name`,
                            val?.name
                          );
                          setFieldValue(
                            `environmental_food_allergies[${idx}].brand_name`,
                            val?.brand_name
                          );
                          setFieldValue(
                            `environmental_food_allergies[${idx}].allergen_id`,
                            val?.allergen_id
                          );
                          setFieldValue(
                            `environmental_food_allergies[${idx}].allergen_type`,
                            val?.allergen_type
                          );
                          setFieldValue(
                            `environmental_food_allergies[${idx}].is_active`,
                            val?.is_active
                          );
                        }
                      }}
                    />
                  ),
                });
              }}
              label="Allergic to"
              id={`environmental_food_allergies[${idx}].name`}
              name={`environmental_food_allergies[${idx}].name`}
              disabled={environmental_food_allergies[idx].id}
            />
            <Spacer height={23} />
            <Input
              type={input_types.DROPDOWN}
              default={"Select a value"}
              options={allergyReactions}
              label="Reaction"
              id={`environmental_food_allergies[${idx}].reaction`}
              name={`environmental_food_allergies[${idx}].reaction`}
              disabled={environmental_food_allergies[idx].id}
            />
            {environmental_food_allergies[idx].reported_by ? (
              <p className="mt-8 font-semibold text-2xl">
                Reported by {environmental_food_allergies[idx].reported_by} on{" "}
                {moment(environmental_food_allergies[idx]?.reported_at).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </p>
            ) : (
              <p
                onClick={() => {
                  setFieldValue(
                    "environmental_food_allergies",
                    environmental_food_allergies?.filter(
                      (_, index) => index !== idx
                    )
                  );
                }}
                className="text-red text-center font-light text-2xl underline mt-12 cursor-pointer"
              >
                Remove
              </p>
            )}
          </div>
        );
      })}
      <p
        onClick={() => {
          setFieldValue("environmental_food_allergies", [
            ...environmental_food_allergies,
            { name: "", reaction: "", category: "environment_or_food_allergy" },
          ]);
        }}
        className="text-2xl font-light underline text-orange mt-14 cursor-pointer"
      >
        Add Environmental / Food Allergy
      </p>
    </div>
  );
}

export default AddHealthProfileAllergies;
